.container {
  width: 100%;
  height: 70px;
  padding: 0;
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin-right: 100px;
  align-content: center;
  background-color: rgb(40, 37, 37);
}
.TOP {
  display: flex;
  flex-direction: row;
}

.linkTo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 98%;
  padding: 0px 1.75rem;
  color: #f2f1ef;
  cursor: pointer;
  transition: 0.5s all ease;
  text-decoration: none;
}

.linkTo:hover {
  color: #f2f1ef;
  background-color: var(--main-bg-color);
  border-bottom: 5px solid black;
  transition: 0.5s all ease;
}
.div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoContainer {
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 400;
  padding: 0 50px;
  height: 100%;
  z-index: 1;
  color: aliceblue;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.logoImg {
  width: 100%;
  height: 100%;
}
.primary {
  color: var(--main-bg-color);
}

.mobileIcon {
  display: none;
}

.wrapper ul {
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin-right: 2rem;
  list-style: none;
  margin: 0;
}

.wrapper ul li {
  height: 100%;
  display: flex;
}

.linkTo::selection {
  border-bottom: 5px solid black;
}
.div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .TOP {
    width: 100%;
    justify-content: space-between;
  }
  .wrapper ul {
    background-color: var(--main-back-color);
    z-index: 9999;
    position: absolute;
    top: 70px;
    left: -115%;
    width: 100%;
    height: calc(100% - 70px);
    justify-content: center;
    flex-direction: column;
    align-items: center;
    transition: 0.5s all ease;
    margin: 0;
    padding: 0;
  }
  .wrapper ul li {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
  .mobileIcon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .mobileIcon svg {
    fill: aliceblue;
    margin-right: 0.5rem;
  }
  .div {
    width: 30%;
    justify-content: center;
    font-size: x-large;
  }
  .linkTo {
    background-color: var(--main-back-color);
  }
  .linkTo:hover,
  .linkTo::selection {
    border: 0;
  }
}

