.CONTAINER {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: -webkit-fill-available;
    background-color: rgba(0, 0, 0, 0.555);
    background-size: cover;
}

.BANNER {
    font-size: 85px;
    color:#F2F1EF;
    padding: 20px;
}

.CONTAINER h1 {
    margin-block-start: 5px;
    margin-block-end: 5px;
    color: var(--main-ligth-letter);
    text-transform: uppercase;
}

.PANEL {
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 100vh;
    max-height: 620px;
    padding: 10px;
}

.CALENDAR {
    display: flex;
    flex-direction: column;
    background-color: var(--main-ligth-letter);
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 20px 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.DATE {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 66%;
    height: 100%;
}

.RESUME {
    width: 33%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgb(65, 64, 64);
    align-items: center;
    gap: 20px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.RESUME .input {
    padding: 5px;
    background-color: transparent;
    border: none;
    width: 70%;
    border-bottom: 1px solid gray;
    color: white;
    font-family: "Ubuntu Condensed", Arial, Helvetica, sans-serif;
    text-transform: uppercase;
}
.input[type=number]::-webkit-inner-spin-button, 
.input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 20px rgb(65, 64, 64) inset;
    color: white
}

.RESUME .select {
    width: 70%;
}
.RESUME .inputSubmit {
    width: 70%;
    padding: 10px;
    border: none;
    color: white;
    background-color: var(--main-bg-color);
    border: 1px solid rgb(40, 37, 37);
    transition: 0.3s all ease;
}

.RESUME .inputSubmit:hover {
    background-color: rgb(40, 37, 37);
    color: #F2F1EF;
    border: 1px solid rgb(40, 37, 37);
    transition: 0.3s all ease;
}


.TITLE {
    color: var(--main-bg-color);
    font-size: x-large;
    letter-spacing: 5px;
}

.SELECTS {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 10px;
}

.SELECTS select{
    padding: 5px;
    border-radius: 10px;
    background-color: antiquewhite;
    width: 100px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 1080px) {
    .CONTAINER {
        height: calc(100vh + 370px);
    }
    .PANEL{
        flex-direction: column;
        max-height: 100vh;
    }
    .BANNER {
        font-size: 70px;
    }
    .CALENDAR{
        padding: 0;
        border-radius: 0;
        margin: auto;
    }
    .DATE{
        width: 100%;
        margin: auto;
    }
    .RESUME{
        width: 100%;
        border-radius: 0;
        padding-bottom: 20px;
    }
}