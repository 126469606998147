.inputBtn{
    width: 100px;
    height: 50px;
    padding: 10px;
    border: none;
    color: white;
    background-color: var(--main-bg-color);
    border: 1px solid rgb(40, 37, 37);
    transition: 0.3s all ease;
  }

  .inputBtn:hover {
    background-color: #F2F1EF;
    color: rgb(40, 37, 37);
    border: 1px solid rgb(40, 37, 37);
    transition: 0.3s all ease;
  }