.CONTAINER {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 20px;
    color: var(--main-ligth-letter);
    /* font-size: larger; */
}

.PANEL, .ENV_POLICY {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    width: 90%;
    min-height: 520px;
}

.PANEL {
    background-color: var(--main-card-color);
}
.ENV_POLICY {
    background-color: var(--main-bg-color);
}
.BANNER {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 180px;
    width: 90%;
    height: 420px;
}

.COLUMN, .COLUMN1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25%;
    min-width: 240px;
    min-height: 340px;
    height: 100%;
    padding: 30px;
    gap: 20px;
    text-align: justify;
}

.COLUMN hr, .COLUMN1 hr {
    width: 100%;
}

.COLUMN, .COLUMN1 {
    word-spacing: 5px;
    line-height: 25px;
    font-size: larger;
    letter-spacing: 1px;
}

.COLUMN1 {
    width: 50% ;
}


.HEAD_CARD {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 24px;
}

.HEAD_CARD > svg {
    font-size: xx-large;
}

@media screen and (max-width: 960px) {
    .PANEL, .ENV_POLICY {
        flex-direction: column;
    }
    .COLUMN, .COLUMN1 {
        width: auto;
        padding-block: 5px;
    }
    .BANNER {
        font-size: 70px;
        height: 200px;
    }
}
