:root {
  /* --main-bg-color: #F8565D; */
  --main-bg-color: #FE0000;
  --main-back-color: rgb(40, 37, 37);
  --main-card-color: rgb(56, 55, 55);
  --main-ligth-letter: #F2F1EF;
}
body {
  margin: 0;
  font-family: "Ubuntu Condensed", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-back-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--main-bg-color) transparent;
  }
  
  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 3px;
    width: 8px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 6px;
  }