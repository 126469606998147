.mainApp {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    padding: 20px;
    align-self: center;
    /* gap: 24px; */
}

.containerBand {
    display: flex;
    flex-direction: column;
}

.containerBand > hr {
    margin: 0;
}

.containerFreeApp {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 20px;
}

.freeApp, .pastApp {
    width: auto;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
}

.freeApp:hover {
    background-color: black;
    color: white;
}

.selectedApp {
    width: auto;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    background-color: black;
    color: white;
}

.pastApp {
    color: gray;
    cursor: not-allowed;
  }

@media (width <= 600px){
    .mainApp {
        min-width: auto;
    }
}