/* .CONTAINER {
    display: flex;
    flex-direction: column;
    color: var(--main-ligth-letter);
    align-items: center;
}

.CONTENT{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 240px;
    width: 90%;
    gap: 5px;    
    overflow: hidden;
}
.CLIENT {
    display: flex;
    height: 120px;
    width: 120px;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color:var(--main-card-color);
    padding: 25px;
    border-radius: 5px;
    font-size: x-large;
} */

  
  .marquee {
    width: 90%;
    height: 20vh;
    background-color: #111;
    color: #eee;
    overflow: hidden;
    position: relative;
  }
  .marquee:before, .marquee:after {
    position: absolute;
    top: 0;
    width: 10rem;
    height: 100%;
    content: "";
    z-index: 1;
  }
  .marquee:before {
    left: 0;
    background: linear-gradient(to right, #111 0%, transparent 100%);
  }
  .marquee:after {
    right: 0;
    background: linear-gradient(to left, #111 0%, transparent 100%);
  }
  .marquee_content {
    list-style: none;
    height: 100%;
    display: flex;
    margin: 0;
    animation: scrolling 23s linear infinite;
  }

  /* .marquee_content:hover {
    animation-play-state: paused;
  } */
  @keyframes scrolling {
    0% { transform: translateX(0); }
    100% { transform: translateX(-198vw); }
  }

  @media (width < 500px) {
    @keyframes scrolling {
      0% { transform: translateX(0); }
      100% { transform: translateX(-691vw); }
    }
  }
  .marquee_content li {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-shrink: 0;
    width: 242.8px;
    max-height: 100%;
    font-size: 2rem;
    white-space: nowrap;
    background: #2d2d2d;
  }
  
  /* .marquee_content li img {
    width: 100%;
    height: 100%;
    border: 2px solid #eee;
  } */
  
  /* @media (max-width: 600px) {
    html { font-size: 12px; }
    :root {
      --marquee-width: 100vw;
      --marquee-height: 16vh;
      --marquee-elements-displayed: 3;
    }
    .marquee:before, .marquee:after { width: 5rem; }
  } */