.PANEL {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  background-color: var(--main-card-color);
  color: var(--main-ligth-letter);
}
.ICON {
  height: 48px;
  width: 48px;
}

/*---------------*/
/***** Ming *****/
/*---------------*/

figure.effect_ming {
  position: relative;
  background: #030c17;
  display: flex;
  align-items: center;
  width: 40%;
  padding: 8px;
  border-radius: 10px;
}

figure.effect_ming a {
  padding: 5px;
  z-index: 1000;
}
figure.effect_ming a button {
  cursor: pointer;
}

figure.effect_ming div {
  display: flex;
  align-items: center;
  gap: 24px;
  padding-inline: 1em;
  /* flex-direction: column; */
}

figure.effect_ming figcaption {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

figure.effect_ming svg {
  color: var(--main-bg-color);
}

figure.effect_ming:hover svg {
  -webkit-transition: 0.35s;
  transition: 0.35s;
  transform: scale(0.8);
  color: inherit;
  opacity: 0.4;
}

figure.effect_ming:hover p {
  transform: scale(1.2);
  width: 80%;
  margin-left: 30px;
}

figure.effect_ming figcaption::before {
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  border: 2px solid var(--main-bg-color);
  border-radius: 10px;
  box-shadow: 0 0 10px 10px rgba(188, 109, 113, 0.1);
  content: "";
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale3d(1.1, 1.1, 1);
  transform: scale3d(1.1, 1.1, 1);
}

figure.effect_ming h2 {
  margin: o auto;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

figure.effect_ming p {
  padding: 1em;
  opacity: 1;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: 0.35s;

  width: 80%;
  /* -webkit-transform: scale(1.5);
	transform: scale(1.5); */
}

figure.effect_ming:hover h2 {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

figure.effect_ming:hover figcaption::before {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

@media (width <= 1024px) {
  .PANEL {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  figure.effect_ming {
    flex-direction: column;
    width: auto;
  }

  figure.effect_ming figcaption {
    align-items: center;
  }

  figure.effect_ming figcaption div {
    gap: 8px;
  }
  figure.effect_ming figcaption p {
    text-align: justify;
  }

  figure.effect_ming:hover p {
    margin-left: 0;
  }
}
