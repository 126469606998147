.container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: 12px;
}
.mesescontainer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}
.diascontainer {
  display: flex;
  flexwrap: nowrap;
  overflow-x: auto;
  padding: 10px 0;
  width: 100%; 
  max-width: calc(100%/ 1.4); 
  gap: 12px;
}
.diaitem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  min-width: 50px;
  border-radius: 15px;
  transition: all 0.3s ease;
  gap: 30px;
}
.dianombre {
  font-size: 12px;
}
.dianumero {
  font-size: 14px;
  font-weight: bold;
}

@media (width <= 600px){
  .diascontainer {
    max-width: calc(100% / 1.1);
    gap: 0;
  }
}