.CONTAINER {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 20px;
    color: var(--main-ligth-letter);
}

.PANEL {
    display: flex;
    justify-content: center;
    width: 90%;
    background-color: var(--main-card-color);
    color: var(--main-ligth-letter)
}

.container img {
    width: 90%;
}

.BANNER {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 180px;
    width: 90%;
}

@media (width <= 1024px){
    .PANEL {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .BANNER {
        font-size: 70px;
    }
}