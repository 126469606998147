.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    width: 100%;
    height: 100%;
    background-color: rgb(40, 37, 37);
    /* background-image: linear-gradient(
      to bottom,
      var(--main-bg-color),
      rgb(43, 14, 13)
    ), url("../../img/brush-leaving-strokes-in-white-foam-on-windshield-of-a-car-washed-in-picture-id1357628053.jpg");
    background-size: cover; */
  }

.contain {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  width: 90%;
  height: 100%;

}
.contain2 {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  width: 100%;
  height: 100%;

}

.landingLeft, .landingRight {
  display: flex;
  align-items: center;

}

.landingLeft {
  width: 64%;
  justify-content: right;
  overflow: hidden;
  max-height: 500px;
}
.landingRight {
  width: 36%;
  overflow: hidden;
  justify-content: left;
  background-color: var(--main-bg-color);
  font: 56px/50px "Ubuntu Condensed", Arial, Helvetica, sans-serif;
  color: white;
}

.landingRight span {
  padding: 10%;
}

@media (width <= 960px) {
  .contain {
    flex-direction: column;
  }
  .landingLeft, .landingRight {
    width: auto;
  }

  .landingRight {
    min-height: 300px;
  }
}