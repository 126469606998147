.bottomPrimary {
    display: flex;
    flex-direction: row;
    bottom: 0;
    width: 100%;
    height: 330px;
    justify-content: space-around;
    background-color: rgb(40, 37, 37);
    backdrop-filter: blur(30px);
    text-align: center;
  }

  .left {
    margin: auto;
    width: 50%;
  }

  .social {
    display: flex;
    margin-top: 10px;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }

  .social a {
    display: flex;
    width: 2em;
    height: 2em;
    border: 1px solid white;
    border-radius: 100%;
    padding: 7px;
    transition: 0.5s;
    justify-content: center;
    align-items: center;
  }

  .social a:hover {
    background-color: var(--main-bg-color);
    border-color: transparent;
    color: white;
  }

  .iconBottom {
    height: 2em;
    width: 2em;
    text-decoration: none;
    color:lightgray;
  }

  .address {
    display: flex;
    align-self: center;
    font-size: 18px;
    word-spacing: 5px;
    letter-spacing: 2px;
    padding: 15px;
    color: lightgray;
    justify-content: center;
  }

  .copyr {
    display: flex;
    flex-direction: column;
    align-self: center;
    font-size: 12px;
    padding: 15px;
    color: lightgray;
    justify-content: center;
    gap: 5px
  }


  .copyr a {
    text-decoration: none;
    color: var(--main-light-letter)
  }

  .address {
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
  .copyr:hover {
    color: var(--main-bg-color);
  }

  .right{
     width: 50%;
     display: flex;
    justify-content: center;
    align-items: center;
    min-height: 333px;
  }
  .map {
    display: flex;
    align-items: center;
    height: 100%;
    width: 80%;
  }

  .map img {
    height: 80%;
    width: 90%;
  }

  @media (width <= 760px) {
    .bottomPrimary {
      flex-direction: column-reverse;
      height: auto;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .right {
      width: 100%;
      height: 390px;
    }

    .map {
      width: 100%;
    }
  }