.Contact {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
.Contact hr {
  display: flex;
  width: auto;
  max-width: 400px;
  margin: 0;
}
  .WRAPPER{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 90%;
    background-color: #F2F1EF;
  }

  .LEFT, .RIGHT {
    display: flex;
    flex-direction: column;
    height: auto;
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .LEFT {
    margin-inline: 50px;
    width: 30%;
 }

  .RIGHT {
    align-items: left;
    width: 50%;
  }
  
  .LEFT span {
    line-height: 25px;
    letter-spacing: 1px;
  }
  .Contact form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
    max-width: 400px;
    gap: 30px;
  }
  
  .input{
    padding: 10px;
    background-color: transparent;
    /* border: none; */
    width: 100%;
    /* border-bottom: 1px solid gray; */
    border: 1px solid rgb(197, 196, 196);
    color: var(--main-back-color);
    font-family: "Ubuntu Condensed", Arial, Helvetica, sans-serif;
    text-transform: uppercase;
  }
  
  .inputBtn{
    width: 70%;
    padding: 10px;
    color: white;
    border: none;
    background-color: var(--main-bg-color);
    transition: 0.3s all ease;
  }

  .inputBtn:hover {
    background-color: rgb(40, 37, 37);
    transition: 0.3s all ease;
  }
  
  a {
      text-decoration: none;
  }

  @media (width <= 960px){
    .WRAPPER {
      flex-direction: column;
      width: 100%;
    }
    .LEFT, .RIGHT{
      width: auto;
    }
    .RIGHT {
      margin-inline: 50px;
    }
  }